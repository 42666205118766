import React, { useEffect, useRef } from 'react';
import 'tailwindcss/tailwind.css'; // Make sure to include Tailwind CSS in your project

const NotRegisteredPage = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const width = canvas.width = window.innerWidth;
    const height = canvas.height = window.innerHeight;
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'.split('');
    const fontSize = 16;
    const numLetters = 100; // Number of letters to generate
    const lettersData = Array(numLetters).fill(null).map(() => ({
      x: Math.random() * width,
      y: Math.random() * height,
      speed: Math.random() * 3 + 1, // Random speed
      letter: letters[Math.floor(Math.random() * letters.length)]
    }));

    const draw = () => {
      ctx.fillStyle = 'rgba(0, 0, 0, 0.05)';
      ctx.fillRect(0, 0, width, height);
      ctx.fillStyle = '#0F0';
      ctx.font = `${fontSize}px monospace`;

      lettersData.forEach(letterData => {
        ctx.fillText(letterData.letter, letterData.x, letterData.y);
        
        // Update letter position
        letterData.y += letterData.speed;

        // Reset position if out of bounds
        if (letterData.y > height) {
          letterData.x = Math.random() * width;
          letterData.y = -fontSize;
          letterData.letter = letters[Math.floor(Math.random() * letters.length)];
          letterData.speed = Math.random() * 3 + 1;
        }
      });
    };

    const interval = setInterval(draw, 33);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative flex flex-col items-center justify-center min-h-screen bg-black text-hacker-green">
      <canvas ref={canvasRef} className="absolute inset-0 w-full h-full"></canvas>
      <div className="relative z-10 p-6 md:p-8 lg:p-12 bg-gray-900 bg-opacity-75 rounded-lg shadow-lg  max-w-3xl">
        <h1 className="text-3xl lg:text-5xl font-bold text-center mb-4">You dont have 0xProfile!</h1>
        <p className="text-lg text-center mb-8">Please register your Profile at <a href="http://0xprofile.com" className="underline text-green-500">0xProfile.com</a>.</p>
        <div className="flex justify-center">
          <a href="http://0xprofile.com" className="bg-green-600 hover:bg-green-700 text-white font-bold py-3 px-6 rounded-lg text-xl transition duration-300">Register Now</a>
        </div>
      </div>
    </div>
  );
};

export default NotRegisteredPage;
