import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { HomeIcon } from '@heroicons/react/24/outline'; // Updated import

const ProfileForm = () => {
  const [username, setUsername] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const canvasRef = useRef(null);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setAlertVisible(false);
    setLoading(true);

    try {
      const response = await axios.post('api/open-profile', { profileName: username });
      setLoading(false);

      if (response.data.success && response.data.success.msg) {
        const userName = response.data.success.name;
        setAlertMessage(response.data.success.msg);
        setAlertVisible(true);

        setTimeout(() => {
          setAlertVisible(false);
          navigate(`/welcome?name=${encodeURIComponent(userName)}`);
        }, 2000);
      } else {
        setAlertMessage(response.data.error.msg);
        setAlertVisible(true);

        setTimeout(() => {
          setAlertVisible(false);
          navigate(`/not-registered`);
        }, 2000);
      }
    } catch (error) {
      setLoading(false);
      setAlertMessage(error.response?.data?.error?.msg || 'An error occurred');
      setAlertVisible(true);

      setTimeout(() => {
        setAlertVisible(false);
      }, 2000);
    }
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const width = canvas.width = window.innerWidth;
    const height = canvas.height = window.innerHeight;
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'.split('');
    const fontSize = 16;
    const numLetters = 100; // Number of letters to generate
    const lettersData = Array(numLetters).fill(null).map(() => ({
      x: Math.random() * width,
      y: Math.random() * height,
      speed: Math.random() * 3 + 1, // Random speed
      letter: letters[Math.floor(Math.random() * letters.length)]
    }));

    const draw = () => {
      ctx.fillStyle = 'rgba(0, 0, 0, 0.05)';
      ctx.fillRect(0, 0, width, height);
      ctx.fillStyle = '#0F0';
      ctx.font = `${fontSize}px monospace`;

      lettersData.forEach(letterData => {
        ctx.fillText(letterData.letter, letterData.x, letterData.y);
        
        // Update letter position
        letterData.y += letterData.speed;

        // Reset position if out of bounds
        if (letterData.y > height) {
          letterData.x = Math.random() * width;
          letterData.y = -fontSize;
          letterData.letter = letters[Math.floor(Math.random() * letters.length)];
          letterData.speed = Math.random() * 3 + 1;
        }
      });
    };

    const interval = setInterval(draw, 33);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative flex items-center justify-center min-h-screen bg-black text-hacker-green">
      <canvas ref={canvasRef} className="absolute inset-0 w-full h-full"></canvas>
      <div className="relative z-10 p-6 md:p-8 lg:p-12 bg-gray-900 bg-opacity-75 rounded-lg shadow-lg max-w-sm sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl">
        <form id="profileForm" onSubmit={handleSubmit} className="space-y-6">
          <div className="flex flex-col items-center space-y-4">
            <HomeIcon className="h-12 w-12 text-hacker-green" aria-hidden="true" />
            <h1 className="text-center text-2xl sm:text-3xl md:text-4xl font-extrabold text-hacker-green">
              0xHouse
            </h1>
            <h2 className="text-center text-2xl sm:text-3xl md:text-4xl font-extrabold text-hacker-green">
            A Hacker House On-Chain
            </h2>
          </div>
          <div className="space-y-4">
            <label htmlFor="username" className="block text-hacker-green text-sm font-bold">
            Check if your 0xUsername is Eligible to 0xHouse
            </label>
            <div className="flex items-center">
              <span className="inline-block bg-gray-700 text-hacker-green px-3 py-2 rounded-l-md">0x</span>
              <input
                type="text"
                id="username"
                name="username"
                className="shadow appearance-none border rounded-r-md w-full py-2 px-3 text-hacker-green leading-tight focus:outline-none focus:shadow-outline bg-gray-700"
                value={username}
                onChange={(e) => setUsername(e.target.value.trim())}
                required
              />
            </div>
            <p className="text-xs text-white">
              Note: Go to <a href="https://0xProfile.com" target="_blank" rel="noopener noreferrer" className="text-hacker-green underline">0xProfile.com</a> and grab your 0xUsername if you haven’t got one already.
            </p>
          </div>
          {alertVisible && (
            <div id="alert" className="text-center text-sm italic text-red-500">
              {alertMessage}
            </div>
          )}
          {loading && (
            <div id="loading" className="text-center text-sm italic text-blue-500">
              Loading...
            </div>
          )}
          <div className="flex items-center justify-center">
            <button
              id="submitBtn"
              className="bg-green-600 hover:bg-green-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300"
              type="submit"
            >
              Check
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileForm;
