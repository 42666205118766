import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProfileForm from "./ProfileForm";
import WelcomePage from "./WelcomePage";
import NotRegisteredPage from "./NotRegisteredPage";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ProfileForm />} />
        <Route path="/welcome" element={<WelcomePage />} />
        <Route path="/not-registered" element={<NotRegisteredPage />} />
      </Routes>
    </Router>
  );
};

export default App;
