import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import 'tailwindcss/tailwind.css'; // Make sure to include Tailwind CSS in your project
import { HomeIcon } from '@heroicons/react/24/outline'; // Updated import

const WelcomePage = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const name = query.get('name');
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const width = canvas.width = window.innerWidth;
    const height = canvas.height = window.innerHeight;
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'.split('');
    const fontSize = 16;
    const numLetters = 100; // Number of letters to generate
    const lettersData = Array(numLetters).fill(null).map(() => ({
      x: Math.random() * width,
      y: Math.random() * height,
      speed: Math.random() * 3 + 1, // Random speed
      letter: letters[Math.floor(Math.random() * letters.length)]
    }));

    const draw = () => {
      ctx.fillStyle = 'rgba(0, 0, 0, 0.05)';
      ctx.fillRect(0, 0, width, height);
      ctx.fillStyle = '#0F0';
      ctx.font = `${fontSize}px monospace`;

      lettersData.forEach(letterData => {
        ctx.fillText(letterData.letter, letterData.x, letterData.y);
        
        // Update letter position
        letterData.y += letterData.speed;

        // Reset position if out of bounds
        if (letterData.y > height) {
          letterData.x = Math.random() * width;
          letterData.y = -fontSize;
          letterData.letter = letters[Math.floor(Math.random() * letters.length)];
          letterData.speed = Math.random() * 3 + 1;
        }
      });
    };

    const interval = setInterval(draw, 33);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-gray-900 via-black to-gray-900 text-hacker-green">
      <canvas ref={canvasRef} className="absolute inset-0 w-full h-full"></canvas>
      <div className="relative z-10 p-4 sm:p-6 md:p-8 lg:p-12 bg-gray-900 bg-opacity-75 rounded-lg shadow-2xl max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl 2xl:max-w-2xl">
        <div className="flex flex-col items-center space-y-4">
          <HomeIcon className="h-12 w-12 text-hacker-green" aria-hidden="true" />
          <h1 className="text-2xl sm:text-3xl md:text-2xl lg:text-3xl xl:text-4xl font-extrabold mb-6 text-center animate-pulse drop-shadow-md">Hi {name}</h1>
          {name === 'Prashanth BM' || name === 'Saran kumar' ? (
            <>
              <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-3xl xl:text-4xl font-bold text-center drop-shadow-md">You are The Founder.</h1>
              <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-3xl xl:text-4xl font-bold text-center drop-shadow-md">You are in the house!</h1>
            </>
          ) : (
            <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-3xl xl:text-4xl font-bold text-center drop-shadow-md">(You are on Wait List)</h1>
          )}
          <h1 className="text-center text-2xl sm:text-3xl md:text-4xl font-extrabold text-hacker-green">
            0xHouse
          </h1>
          <h2 className="text-center text-2xl sm:text-3xl md:text-4xl font-extrabold text-hacker-green">
            A Hacker House On-Chain
          </h2>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
